import {
  takeEvery,
  select,
  put,
  call,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';

import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';

import UserConstants from '../constants/UserConstants';
import UserRepository from '../repositories/UserRepository';
import AuthDataStorage from '../helpers/StorageHelpers/AuthDataStorage';
import { alertBottomDisplay } from '../actions/AlertBoxAction';

import RoutePathConstants from '../constants/RoutePathConstants';
import { store } from '../store';
import routes from '../lib/ApiRoutes';
import { headers } from '../lib/FetchHelper';
const { dashboard } = RoutePathConstants;
import { getMyProfileDetail as getMyProfileDetailAction } from './../actions/UserActions';

const {
  GET_USER,
  FILTER_SEARCH,
  GET_USER_DETAIL,
  GET_MY_PROFILE_DETAIL,
  ENDORSE_USER,
  REMOVE_ENDORSE_USER,
  FAVOURITE_USER,
  REMOVE_FAVOURITE_USER,
  GET_FAVOURITE_USERS,
  GET_MATCH_RECOMMENDATION,
  GET_SAME_TOPIC_USERS,
  SORT_RESULT,
  SEARCH_TOPIC,
  UPDATE_EDITED_USER_PROFILE,
  UPDATE_PROFILE_FIELD_VALUE,
  UPDATE_PROFILE,
  UPLOAD_PROFILE_IMAGE,
  UPLOAD_DOCUMENT,
  UPLOAD_IMAGE,
  EMPTY_USER,
  EMPTY_IMAGE,
  HIDE_USER,
  UNHIDE_USER,
  SEND_FEEDBACK,
  TOGGLE_ONLINE,
  GET_TOPIC_DISCUSSION_THREAD,
} = UserConstants;

export function* watchGetUser() {
  yield takeEvery(`${GET_USER}_REQUEST`, function*() {
    try {
      const sortId = yield select(
        (state) => state.User.selectedOption.id
      );
      yield call(sortUserList, sortId);
      const filterUsedProperties = yield select(
        (state) => state.User.userListAfterSortResult
      );
      const filteredUserList = filterUsedProperties.filter(
        (filteredList) =>
          filteredList['uuid'] !== AuthDataStorage.getUuid()
      );
      yield put({
        type: `${GET_USER}_SUCCESS`,
        payload: filteredUserList,
      });
      yield delay(200);
      yield put({
        type: `${GET_USER}_STOP_LOADING`,
      });
    } catch (errors) {
      yield put({
        type: `${GET_USER}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchEmptyUser() {
  yield takeEvery(`${EMPTY_USER}_REQUEST`, function*() {
    try {
      yield put({
        type: `${EMPTY_USER}`,
      });
    } catch (errors) {
      return errors;
    }
  });
}

export function* watchEmptyImage() {
  yield takeEvery(`${EMPTY_IMAGE}_REQUEST`, function*() {
    console.log('EMPTY 1');
    try {
      console.log('EMPTY 2');

      yield put({
        type: `${EMPTY_IMAGE}`,
      });
    } catch (errors) {
      console.log('EMPTY 3');
      return errors;
    }
  });
}

export function* filterSearch() {
  yield takeEvery(`${FILTER_SEARCH}_REQUEST`, function*({
    payload: { searchInput },
  }) {
    try {
      const userList = yield select((state) => state.User.userList);
      const searchResult = yield call(
        UserRepository.filterSearch,
        searchInput
      );

      yield put({
        type: `${FILTER_SEARCH}_SUCCESS`,
        payload: searchInput === '' ? userList : searchResult.users,
      });
    } catch (errors) {
      yield put({
        type: `${FILTER_SEARCH}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetUserListAfterSortResult() {
  yield takeEvery(`${SORT_RESULT}_REQUEST`, function*({
    payload: params,
  }) {
    yield call(sortUserList, params);
  });
}

function* sortUserList(params) {
  try {
    const sortedUserList = yield call(
      UserRepository.sortResult,
      params
    );
    const filteredSortedUserList = sortedUserList.users.map(
      (newUserList) =>
        _pick(
          newUserList,
          'uuid',
          'image_url',
          'username',
          'online',
          'is_online',
          'biography',
          'mentor',
          'roles',
          'is_mentor',
          'uu_id'
        )
    );
    yield put({
      type: `${SORT_RESULT}_SUCCESS`,
      payload: filteredSortedUserList,
    });
  } catch (errors) {
    yield put({
      type: `${SORT_RESULT}_FAILURE`,
      payload: errors,
    });
  }
}

function* getUserDetail(userId, fresh) {
  try {
    const userDetail = yield call(
      UserRepository.getUserDetail,
      userId,
      fresh
    );
    console.log('userDetail');
    console.log(userDetail);
    yield put({
      type: `${GET_USER_DETAIL}_SUCCESS`,
      payload: userDetail,
    });
    yield delay(200);
    yield put({
      type: `${GET_USER_DETAIL}_STOP_LOADING`,
    });
  } catch (errors) {
    yield put({
      type: `${GET_USER_DETAIL}_FAILURE`,
      payload: errors,
    });
  }
}

export function* watchGetUserDetail() {
  yield takeEvery(`${GET_USER_DETAIL}_REQUEST`, function*({
    payload: { userId, fresh },
  }) {
    yield call(getUserDetail, userId, fresh);
  });
}

export function* watchGetMyProfileDetail() {
  yield takeEvery(`${GET_MY_PROFILE_DETAIL}_REQUEST`, function*({
    payload: { fresh },
  }) {
    yield call(getMyProfileDetail, fresh);
  });
}

export function* getMyProfileDetail(fresh) {
  try {
    const myProfileDetail = yield call(
      UserRepository.getMyProfileDetail,
      fresh
    );

    yield put({
      type: `${GET_MY_PROFILE_DETAIL}_SUCCESS`,
      payload: myProfileDetail,
    });
    yield delay(200);
    yield put({
      type: `${GET_MY_PROFILE_DETAIL}_STOP_LOADING`,
    });
  } catch (errors) {
    yield put({
      type: `${GET_MY_PROFILE_DETAIL}_FAILURE`,
      payload: errors,
    });
  }
}

export function* watchEndorseUser() {
  yield takeEvery(`${ENDORSE_USER}_REQUEST`, function*({
    payload: { topicId, userId },
  }) {
    try {
      yield call(UserRepository.endorseUser, topicId, userId);
      yield call(getUserDetail, userId, false);
    } catch (errors) {
      yield put({
        type: `${ENDORSE_USER}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

export function* watchgetUserTotalCount() {
  yield takeEvery('GET_COUNT_REQUEST', function*({
    payload: { count },
  }) {
    try {
      yield put({
        type: 'GET_TOTAL_USERS_SUCCESS',
        payload: count,
      });
    } catch (errors) {
      return errors;
    }
  });
}

export function* watchRemoveEndorseUser() {
  yield takeEvery(`${REMOVE_ENDORSE_USER}_REQUEST`, function*({
    payload: { topicId, userId },
  }) {
    try {
      yield call(UserRepository.removeEndorseUser, topicId, userId);
      yield call(getUserDetail, userId, false);
    } catch (errors) {
      yield put({
        type: `${ENDORSE_USER}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

export function* watchFavouriteUser() {
  yield takeEvery(`${FAVOURITE_USER}_REQUEST`, function*({
    payload: { userId },
  }) {
    try {
      yield call(UserRepository.favouriteUser, userId);
      yield call(getUserDetail, userId, false);
    } catch (errors) {
      yield put({
        type: `${FAVOURITE_USER}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

export function* watchRemoveFavouriteUser() {
  yield takeEvery(`${REMOVE_FAVOURITE_USER}_REQUEST`, function*({
    payload: { userId },
  }) {
    try {
      yield call(UserRepository.removeFavouriteUser, userId);
      yield call(getUserDetail, userId, false);
    } catch (errors) {
      yield put({
        type: `${REMOVE_FAVOURITE_USER}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

export function* watchHideUser() {
  yield takeEvery(`${HIDE_USER}_REQUEST`, function*() {
    try {
      yield call(UserRepository.hideUserRequest);
    } catch (errors) {
      yield put({
        type: `${HIDE_USER}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

export function* watchUnhideUser() {
  yield takeEvery(`${UNHIDE_USER}_REQUEST`, function*() {
    try {
      yield call(UserRepository.unhideUserRequest);
    } catch (errors) {
      yield put({
        type: `${UNHIDE_USER}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

export function* watchGetFavouriteUsers() {
  yield takeEvery(`${GET_FAVOURITE_USERS}_REQUEST`, function*() {
    try {
      const favouriteUserList = yield call(
        UserRepository.getFavouriteUsers
      );
      const filterFavouriteUserList = favouriteUserList.users.map(
        (newFavouriteUserList) =>
          _pick(
            newFavouriteUserList,
            'uuid',
            'image_url',
            'username',
            'online',
            'biography',
            'is_mentor',
            'uu_id',
            'is_online'
          )
      );

      yield put({
        type: `${GET_FAVOURITE_USERS}_SUCCESS`,
        payload: filterFavouriteUserList,
      });
      yield delay(200);
      yield put({
        type: `${GET_FAVOURITE_USERS}_STOP_LOADING`,
      });
    } catch (errors) {
      yield put({
        type: `${GET_FAVOURITE_USERS}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetMatchRecommendations() {
  yield takeEvery(`${GET_MATCH_RECOMMENDATION}_REQUEST`, function*() {
    try {
      const recommendationList = yield call(
        UserRepository.getMatchRecommendations
      );
      const filterRecommendationList = recommendationList.users.map(
        (newRecommendationList) =>
          _pick(
            newRecommendationList,
            'uuid',
            'image_url',
            'username',
            'online',
            'biography',
            'is_mentor',
            'uu_id'
          )
      );

      yield put({
        type: `${GET_MATCH_RECOMMENDATION}_SUCCESS`,
        payload: filterRecommendationList,
      });
      yield delay(200);
      yield put({
        type: `${GET_MATCH_RECOMMENDATION}_STOP_LOADING`,
      });
    } catch (errors) {
      yield put({
        type: `${GET_MATCH_RECOMMENDATION}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetSameTopicUsers() {
  yield takeEvery(`${GET_SAME_TOPIC_USERS}_REQUEST`, function*({
    payload: { topicId },
  }) {
    try {
      const sameTopicUserList = yield call(
        UserRepository.getSameTopicUsers,
        topicId
      );
      const filterSameTopicUserList = sameTopicUserList.topic.users.map(
        (newRecommendationList) =>
          _pick(
            newRecommendationList,
            'uuid',
            'image_url',
            'username',
            'online',
            'biography',
            'mentor',
            'is_mentor',
            'uu_id'
          )
      );

      yield put({
        type: `${GET_SAME_TOPIC_USERS}_SUCCESS`,
        payload: filterSameTopicUserList,
      });
      yield put({
        type: `${GET_TOPIC_DISCUSSION_THREAD}_SUCCESS`,
        payload: sameTopicUserList.topic.discussion_thread,
      });
    } catch (errors) {
      yield put({
        type: `${GET_SAME_TOPIC_USERS}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetSearchTopic() {
  yield takeLatest(`${SEARCH_TOPIC}_REQUEST`, function*({
    payload: { topicSearchInput, page, limit },
  }) {
    try {
      var response = yield call(function() {
        return axios.get(
          routes.getSearchTopicInput(
            topicSearchInput,
            page || 1,
            limit
          ),
          {
            headers: {
              'X-Mesensei-Appkey': AuthDataStorage.getAppKey(),
              'X-Mesensei-Apikey': AuthDataStorage.getApiKey(),
              'Content-Type': 'application/json',
              'X-Device': 'web',
            },
          }
        );
      });
      var data = {
        headers: response.headers,
        data: response.data.topics,
      };
      yield put({
        type: `${SEARCH_TOPIC}_SUCCESS`,
        payload: data,
      });
    } catch (errors) {
      yield put({
        type: `${SEARCH_TOPIC}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetTopic() {
  yield takeEvery(`${SEARCH_TOPIC}_REQUEST`, function*({
    payload: { id },
  }) {
    try {
      const topic = yield call(UserRepository.getTopic, id);

      yield put({
        type: `${SEARCH_TOPIC}_SUCCESS`,
        payload: { topic: topic.topic },
      });
    } catch (errors) {
      yield put({
        type: `${SEARCH_TOPIC}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchUpdateEditedUserProfile() {
  yield takeEvery(`${UPDATE_EDITED_USER_PROFILE}_REQUEST`, function*({
    payload: { editFields },
  }) {
    try {
      const myEditedProfileDetail = yield call(
        UserRepository.updateEditedUserProfile,
        editFields
      );

      AuthDataStorage.storeAuthentication(
        myEditedProfileDetail.user.accepted
      );

      yield call(getMyProfileDetail, false);

      yield put({
        type: `${UPDATE_EDITED_USER_PROFILE}_SUCCESS`,
        payload: myEditedProfileDetail,
      });
    } catch (errors) {
      yield put({
        type: `${UPDATE_EDITED_USER_PROFILE}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchUpdateProfile() {
  yield takeEvery(`${UPDATE_PROFILE}_REQUEST`, function*({
    payload: { profile },
  }) {
    try {
      const myEditedProfileDetail = yield call(
        UserRepository.updateProfile,
        profile
      );

      //      console.log(myEditedProfileDetail);

      console.log('****** post my profile');
      yield call(getMyProfileDetail, false);
      //      store.dispatch(getMyProfileDetail());
      yield put({
        type: `${UPDATE_PROFILE}_SUCCESS`,
      });
    } catch (errors) {
      console.log('****** SAVE ERROR');
      yield call(getMyProfileDetail, false);
      yield put({
        type: `${UPDATE_PROFILE}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchUpdateProfileFieldValue() {
  yield takeEvery(`${UPDATE_PROFILE_FIELD_VALUE}_REQUEST`, function*({
    payload: { fieldId, value, valueItems },
  }) {
    try {
      const myEditedProfileDetail = yield call(
        UserRepository.updateProfileFieldValue,
        fieldId,
        value,
        valueItems
      );

      //      console.log(myEditedProfileDetail);

      console.log('****** get my profile');
      yield call(getMyProfileDetail, true);
      //      store.dispatch(getMyProfileDetail());
      yield put({
        type: `${UPDATE_PROFILE_FIELD_VALUE}_SUCCESS`,
      });
    } catch (errors) {
      console.log('****** SAVE ERROR');
      yield call(getMyProfileDetail, true);
      yield put({
        type: `${UPDATE_PROFILE_FIELD_VALUE}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchUploadUserProfileImage() {
  yield takeEvery(`${UPLOAD_PROFILE_IMAGE}_REQUEST`, function*({
    payload: { imageIdentifier, imageData },
  }) {
    try {
      yield call(
        UserRepository.uploadUserProfileImage,
        imageIdentifier,
        imageData
      );

      yield put({
        type: `${UPLOAD_PROFILE_IMAGE}_SUCCESS`,
      });
    } catch (errors) {
      yield put({
        type: `${UPLOAD_PROFILE_IMAGE}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchUploadDocumentCancel() {
  console.log('uploadDocument cancel');
  yield takeEvery(`${UPLOAD_DOCUMENT}_CANCEL`, function*() {
    yield put({
      type: `${UPLOAD_DOCUMENT}_FAILURE`,
    });
  });
}

export function* watchUploadDocument() {
  console.log('uploadDocument user');
  yield takeEvery(`${UPLOAD_DOCUMENT}_REQUEST`, function*({
    payload: { documentData },
  }) {
    try {
      const document = yield call(
        UserRepository.uploadDocument,
        documentData
      );

      console.log(document);

      yield put({
        type: `${UPLOAD_DOCUMENT}_SUCCESS`,
        payload: document,
      });
    } catch (errors) {
      yield put({
        type: `${UPLOAD_DOCUMENT}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchUploadImageCancel() {
  console.log('uploadImage cancel');
  yield takeEvery(`${UPLOAD_IMAGE}_CANCEL`, function*() {
    yield put({
      type: `${UPLOAD_IMAGE}_FAILURE`,
    });
  });
}

export function* watchUploadImage() {
  console.log('uploadImage');
  yield takeEvery(`${UPLOAD_IMAGE}_REQUEST`, function*({
    payload: { imageData },
  }) {
    try {
      const image = yield call(UserRepository.uploadImage, imageData);

      console.log(image);

      yield put({
        type: `${UPLOAD_IMAGE}_SUCCESS`,
        payload: image,
      });
    } catch (errors) {
      yield put({
        type: `${UPLOAD_IMAGE}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchToggleOnline() {
  yield takeEvery(`${TOGGLE_ONLINE}_REQUEST`, function*({ payload }) {
    try {
      if (payload.online) {
        yield call(UserRepository.setUserOnline);
      } else {
        yield call(UserRepository.setUserOffline);
      }
      //      yield put(getMyProfileDetailAction());
    } catch (errors) {
      yield put({
        type: `${TOGGLE_ONLINE}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchSendFeedback() {
  yield takeLatest(`${SEND_FEEDBACK}_REQUEST`, function*({
    payload: { email, name, feedback, alertData },
  }) {
    try {
      const data = yield call(function() {
        const bodyFormData = new FormData();
        bodyFormData.append('api_group', '81770');
        bodyFormData.append(
          'api_secret',
          'JFrNUfeX8hPbrdCCO6EwJWH04nhXFq0fWhSGnXul59mmdPPSLjek6Ma10iOvyFBlNkS18cc3cpgg4VYG53R1GSTx0xSp3kAGuixslAloQsuLdjh9dY1JGK6w8PM1ZcyjkVBwmNK254D9eL8hz0Q6ANtyt5pi7NsizUELyeEu98tdJsk9i15IFo7Ykm7h0pqqaV2yZwSZ'
        );
        bodyFormData.append('client_email', email);
        bodyFormData.append('client_name', name);
        bodyFormData.append('project_id', '574439');
        bodyFormData.append('subject', feedback);

        return fetch('/api/feedback/send', {
          method: 'POST',
          body: bodyFormData,
        });
      });

      store.dispatch(
        alertBottomDisplay({
          title: alertData.success.title,
          text: alertData.success.text,
          buttonText: alertData.success.buttonText,
        })
      );
      yield put({
        type: `${SEND_FEEDBACK}_SUCCESS`,
      });
    } catch (errors) {
      store.dispatch(
        alertBottomDisplay({
          title: alertData.error.title,
          text: alertData.error.text,
          buttonText: alertData.error.buttonText,
        })
      );
      yield put({
        type: `${SEND_FEEDBACK}_FAILURE`,
        payload: errors,
      });
    }
  });
}
